.cag-chart-and-graphing {
  display: flex;
  flex-direction: column;
  background-color: var(--card-background);
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.cag-controls-column {
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.05);
  display: flex;
  flex-direction: column;
}

.cag-chart-column {
  padding: 20px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: 300px;
}

.cag-data-type-selector,
.cag-period-selector {
  margin-bottom: 20px;
}

.cag-h3 {
  color: var(--primary-color);
  margin-bottom: 15px;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.cag-checkbox-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 10px;
}

.cag-modern-checkbox {
  display: flex;
  align-items: center;
  color: var(--text-color);
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.3s ease;
  padding: 4px 4px;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.05);
  height: 40px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.cag-modern-checkbox:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.cag-modern-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.cag-checkmark {
  height: 18px;
  width: 18px;
  min-width: 18px;
  background-color: transparent;
  border: 2px solid var(--primary-color);
  border-radius: 50%;
  margin-right: 8px;
  position: relative;
  transition: all 0.3s ease;
}

.cag-modern-checkbox input:checked ~ .cag-checkmark {
  background-color: var(--primary-color);
}

.cag-checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 5px;
  top: 2px;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  transition: all 0.2s ease;
}

.cag-modern-checkbox input:checked ~ .cag-checkmark:after {
  display: block;
}

.cag-button-group {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  width: 100%;
}

.cag-period-button {
  background-color: rgba(255, 255, 255, 0.05);
  border: none;
  color: var(--text-color);
  padding: 10px 0;
  border-radius: 20px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
}

.cag-period-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.cag-period-button.active {
  background-color: var(--primary-color);
  color: white;
}

.cag-custom-date-picker {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}

.cag-custom-date-input {
  position: relative;
  width: 100%;
}

.cag-custom-date-input input {
  width: 100%;
  padding: 10px;
  padding-right: 0px;
  border: none;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.05);
  color: var(--text-color);
  font-size: 0.9rem;
}

.cag-calendar-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--primary-color);
}

.cag-submit-button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 10px;
  border-radius: 20px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.cag-submit-button:hover {
  background-color: var(--primary-color-dark);
}

/* Ensure the date picker stays within the container */
.react-datepicker-popper {
  z-index: 2;
  width: 100%;
  max-width: 260px; /* Adjust this value as needed */
}

.react-datepicker {
  width: 100%;
}

.react-datepicker__month-container {
  width: 100%;
}

.react-datepicker__month {
  margin: 0;
  padding: 0.4rem;
}

.react-datepicker__day-names, .react-datepicker__week {
  display: flex;
  justify-content: space-around;
}

.react-datepicker__day-name, .react-datepicker__day {
  margin: 0;
  padding: 0.166rem;
  line-height: 1.7rem;
  width: 1.7rem;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block;
  width: 100%;
}

@media (min-width: 768px) {
  .cag-chart-and-graphing {
    flex-direction: row;
  }

  .cag-controls-column {
    width: 30%;
    max-width: 300px;
  }

  .cag-chart-column {
    width: 70%;
  }
}
@media (max-width: 767px) {
  .cag-button-group {
    grid-template-columns: repeat(2, 1fr);
  }

  .cag-period-button {
    font-size: 0.8rem;
    padding: 8px 0;
  }

  .cag-checkbox-container {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  }

  .react-datepicker-popper {
    max-width: calc(100vw - 40px); /* Adjust for padding */
  }
}

