.sensor-popup .leaflet-popup-content-wrapper {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  padding: 0;
  overflow: hidden;
  transition: all 0.3s ease;
}

.sensor-popup .leaflet-popup-content {
  margin: 0;
  width: 240px !important;
}

.sensor-popup-content {
  padding: 20px;
}

.sensor-popup-content h3 {
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 8px 0;
  color: #333;
}

.sensor-popup-content .timestamp {
  font-size: 12px;
  color: #888;
  margin: 0 0 15px 0;
}

.measurements {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.measurement-item {
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 8px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s ease;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.measurement-item:hover {
  background-color: #e0e0e0;
  transform: translateY(-2px);
}

.measurement-label {
  font-weight: 500;
  color: #555;
  margin-right: 10px;
}

.measurement-value {
  font-weight: 600;
  color: #333;
}

.sensor-popup-content button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 12px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.3s ease;
  width: 100%;
}

.sensor-popup-content button:hover {
  background-color: #45a049;
  transform: translateY(-2px);
}

.leaflet-popup-tip {
  background-color: #ffffff;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideIn {
  from { transform: translateY(-10px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

.sensor-popup {
  animation: fadeIn 0.3s ease-out;
}

.sensor-popup-content {
  animation: slideIn 0.3s ease-out;
}

.measurement-item {
  animation: fadeIn 0.3s ease-out;
  animation-fill-mode: both;
}

.sensor-tooltip {
  background-color: rgba(0, 0, 0, 0.8) !important;
  border: none !important;
  border-radius: 4px !important;
  padding: 4px 8px !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  color: white !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2) !important;
  opacity: 0;
  animation: tooltipFadeIn 0.2s ease forwards;
}

@keyframes tooltipFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.sensor-tooltip::before {
  border-top-color: rgba(0, 0, 0, 0.8) !important;
}

.leaflet-tooltip-top:before {
  border-top-color: rgba(0, 0, 0, 0.8) !important;
}
