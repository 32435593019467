/* SensorDataPage.css */
:root {
  --background-color: #1a1a1a;
  --text-color: #ffffff;
  --primary-color: #28a745;
  --secondary-color: #6c757d;
  --success-color: #28a745;
  --danger-color: #dc3545;
  --card-background: #2c2c2c;
}

body {
  font-family: 'Arial', sans-serif;
  line-height: 1.6;
  color: var(--text-color);
  background-color: var(--background-color);
}

.sensor-data-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.loading, .error {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  font-size: 18px;
  color: var(--text-color);
  background-color: var(--card-background);
  border-radius: 8px;
  margin-bottom: 20px;
}

.error {
  color: var(--danger-color);
  background-color: rgba(255, 0, 0, 0.1);
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 10px;
}
