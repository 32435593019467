.gpsmap-container {
  position: relative;
  height: 100vh;
  width: 100%;
}

.gpsmap-loading,
.gpsmap-error {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 1.2rem;
  color: #333;
}

.gpsmap-tracker-marker {
  display: flex;
  justify-content: center;
  align-items: center;
}

.gpsmap-tracker-marker .gpsmap-marker-icon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.gpsmap-tracker-marker.animal .gpsmap-marker-icon {
  background-color: #4CAF50;
}

.gpsmap-tracker-marker.vehicle .gpsmap-marker-icon {
  background-color: #2196F3;
}

.gpsmap-tracker-tooltip {
  font-size: 0.9rem;
  text-align: center;
}

.gpsmap-tracker-panel {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 300px;
  max-height: calc(100vh - 40px);
  overflow-y: auto;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  padding: 20px;
  z-index: 1000;
}

.gpsmap-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
  color: #666;
}

.gpsmap-tracker-panel h2 {
  margin-top: 0;
  margin-bottom: 5px;
}

.gpsmap-tracker-panel .gpsmap-tracker-type {
  color: #666;
  margin-bottom: 15px;
}

.gpsmap-tracker-panel .gpsmap-health-data {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.gpsmap-tracker-panel .gpsmap-health-item {
  display: flex;
  align-items: center;
  gap: 5px;
}

.gpsmap-tracker-panel h3 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 1rem;
}

.gpsmap-tracker-panel .gpsmap-metadata p {
  margin: 5px 0;
}

.gpsmap-tracker-panel .gpsmap-location-history ul {
  list-style-type: none;
  padding: 0;
}

.gpsmap-tracker-panel .gpsmap-location-history li {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  font-size: 0.9rem;
}

.gpsmap-error-popup {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #f44336;
  color: white;
  padding: 15px;
  border-radius: 5px;
  z-index: 1000;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.gpsmap-error-popup button {
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  margin-left: 10px;
  cursor: pointer;
}

.gpsmap-button {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  background-color: #3498db;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  transition: background-color 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.gpsmap-button:hover {
  background-color: #2980b9;
}

.gpsmap-button svg {
  margin-right: 8px;
}

.gpsmap-back-button {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 1000;
}

.gpsmap-back-button span {
  margin-left: 5px;
}

.gpsmap-add-tracker-button {
  position: absolute;
  top: 20px;
  left: 200px;
  z-index: 1000;
}

.gpsmap-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.gpsmap-popup {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  width: 350px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  position: relative;
}

.gpsmap-popup h2 {
  margin-top: 0;
  margin-bottom: 20px;
  color: #333;
}

.gpsmap-popup input, .gpsmap-popup select {
  width: 100%;
  padding: 12px;
  margin-bottom: 15px;
  border-radius: 5px;
  border: 1px solid #ddd;
  font-size: 14px;
}

.gpsmap-button-container {
  display: flex;
  justify-content: space-between;
}

.gpsmap-submit-button, .gpsmap-cancel-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}

.gpsmap-submit-button {
  background-color: #3498db;
  color: #fff;
}

.gpsmap-cancel-button {
  background-color: #e74c3c;
  color: #fff;
}

.gpsmap-error-message, .gpsmap-success-message {
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
}

.gpsmap-error-message {
  background-color: #ffebee;
  color: #c62828;
}

.gpsmap-success-message {
  background-color: #e8f5e9;
  color: #2e7d32;
}

.gpsmap-top-message {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: bold;
}

.gpsmap-error-message.gpsmap-top-message {
  background-color: #ffebee;
  color: #c62828;
  border: 1px solid #c62828;
}

.gpsmap-info-message.gpsmap-top-message {
  background-color: #e3f2fd;
  color: #1565c0;
  border: 1px solid #1565c0;
}

.gpsmap-loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.gpsmap-loader {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: gpsmap-spin 1s linear infinite;
}

@keyframes gpsmap-spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.gpsmap-filter-button {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1000;
}

.gpsmap-user-info-box {
  position: absolute;
  bottom: 10px;
  left: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  z-index: 1000;
  display: flex;
  align-items: center;
}

.gpsmap-map-mode-toggle {
  margin-left: 10px;
  background-color: #4a4a4a;
  border: none;
  color: white;
  padding: 5px 10px;
  border-radius: 3px;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.gpsmap-map-mode-toggle:hover {
  background-color: #5a5a5a;
}

/* Add all the filter popup styles with gpsmap- prefix */
.gpsmap-filter-popup {
  position: absolute;
  top: 70px;
  right: 20px;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.95);
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  max-width: 350px;
  width: calc(100% - 40px);
  max-height: calc(100vh - 100px);
  overflow-y: auto;
  color: #333;
}

/* Mobile styles */
@media (max-width: 768px) {
  .gpsmap-filter-popup {
    position: fixed;
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    max-width: 100%;
    width: 100vw;
    height: 50vh;
    margin: 0;
    padding: 20px;
    border-radius: 20px 20px 0 0;
    box-shadow: 0 -4px 20px rgba(0, 0, 0, 0.15);
    overflow-y: auto;
    box-sizing: border-box;
    transform: translateY(100%);
    opacity: 0;
    transition: transform 0.3s ease-out, opacity 0.2s ease-out;
  }

  .gpsmap-filter-popup.active {
    transform: translateY(0);
    opacity: 1;
  }

  .gpsmap-user-info-box {
    flex-direction: column;
    align-items: flex-start;
  }

  .gpsmap-map-mode-toggle {
    margin-left: 0;
    margin-top: 5px;
  }

  .gpsmap-filter-button {
    top: 110px;
    right: auto;
    left: 20px;
    transition: all 0.3s ease;
  }
}

.gpsmap-filter-section {
  margin-bottom: 20px;
}

.gpsmap-filter-section h3 {
  color: #1976D2;
  margin-bottom: 15px;
}

.gpsmap-filter-section h4 {
  color: #1976D2;
  margin: 0 0 10px 0;
}

.gpsmap-select-buttons {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
}

.gpsmap-select-buttons button {
  padding: 8px 12px;
  background-color: #E3F2FD;
  border: 1px solid #90CAF9;
  color: #1976D2;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
}

.gpsmap-select-buttons button:hover {
  background-color: #BBDEFB;
}

.gpsmap-filter-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 10px;
}

.gpsmap-filter-chip {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  background-color: #E3F2FD;
  border: 1px solid #90CAF9;
  color: #1976D2;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.gpsmap-filter-chip:hover {
  background-color: #BBDEFB;
}

.gpsmap-filter-chip.selected {
  background-color: #2196F3;
  color: white;
  border: 1px solid #1976D2;
}

.gpsmap-filter-chip input[type="checkbox"] {
  margin: 0;
}

.gpsmap-filter-chip span {
  color: inherit;
}

.gpsmap-filter-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  color: #2196F3;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.3s ease;
}

.gpsmap-filter-close-button:hover {
  color: #1976D2;
}
