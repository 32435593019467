.sac-sharing-admin-container {
  display: flex;
  background-color: var(--card-background);
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.sac-sharing-panel, .sac-admin-panel {
  flex: 1;
  padding: 20px;
}

.sac-sharing-panel {
  border-right: 1px solid var(--border-color);
}

.sac-heading {
  color: var(--primary-color);
  margin-bottom: 20px;
  font-size: 1.5rem;
}

.sac-user-list {
  max-height: 300px;
  overflow-y: auto;
  margin-bottom: 20px;
  border: 1px solid var(--border-color);
  border-radius: 8px;
}

.sac-user-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid var(--border-color);
}

.sac-user-item:last-child {
  border-bottom: none;
}

.sac-modern-button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.5px;
}

.sac-modern-button:hover {
  background-color: var(--primary-color-dark);
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.sac-modern-button.sac-danger {
  background-color: var(--danger-color);
}

.sac-modern-button.sac-danger:hover {
  background-color: var(--danger-color-dark);
}

.sac-modern-button.sac-add-user {
  margin-bottom: 20px;
}

.sac-modern-button.sac-remove-user {
  padding: 4px 8px;
  font-size: 10px;
}

.sac-toggle-container {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.sac-toggle-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-right: 10px;
}

.sac-toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.sac-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.sac-slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .sac-slider {
  background-color: #2196F3;
}

input:checked + .sac-slider:before {
  transform: translateX(26px);
}

.sac-error-message {
  color: var(--danger-color);
  margin-top: 10px;
}

.sac-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.sac-popup-content {
  background-color: var(--card-background);
  padding: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 300px;
  width: 100%;
}

.sac-input,
.sac-select {
  padding: 8px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  background-color: var(--secondary-background);
  color: var(--text-color);
}

.sac-popup-content button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.sac-popup-content button:first-of-type {
  background-color: var(--primary-color);
  color: white;
}

.sac-popup-content button.sac-secondary {
  background-color: var(--secondary-background);
  color: var(--text-color);
}

.sac-popup-content button:hover {
  opacity: 0.8;
}

@media (max-width: 768px) {
  .sac-sharing-admin-container {
    flex-direction: column;
  }

  .sac-sharing-panel, .sac-admin-panel {
    width: 100%;
  }

  .sac-sharing-panel {
    border-right: none;
    border-bottom: 1px solid var(--border-color);
  }

  .sac-user-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .sac-user-item span {
    margin-bottom: 5px;
  }

  .sac-modern-button {
    width: 100%;
    margin-bottom: 10px;
  }
}
