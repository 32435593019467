.ed-export-data {
  background-color: var(--card-background);
  border-radius: 12px;
  padding: 24px;
  margin-bottom: 24px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.ed-export-data:hover {
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.ed-export-data h2 {
  margin-bottom: 24px;
  color: var(--primary-color);
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  gap: 10px;
}

.ed-export-form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.ed-date-range {
  display: flex;
  gap: 16px;
}

.ed-date-range input {
  flex: 1;
  padding: 10px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: rgba(255, 255, 255, 0.05);
  color: var(--text-color);
  border-radius: 6px;
  transition: border-color 0.3s ease;
}

.ed-date-range input:focus {
  outline: none;
  border-color: var(--primary-color);
}

.ed-data-selection {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  justify-content: flex-start;
}

.ed-checkbox-label {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  padding: 8px 16px;
  background-color: rgba(255, 255, 255, 0.08);
  border-radius: 30px;
  transition: all 0.3s ease;
  font-size: 14px;
  line-height: 1;
  min-height: 36px;
  box-sizing: border-box;
}

.ed-checkbox-label:hover {
  background-color: rgba(255, 255, 255, 0.12);
}

.ed-checkbox-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.ed-checkmark {
  height: 18px;
  width: 18px;
  background-color: transparent;
  border: 2px solid var(--primary-color);
  border-radius: 50%;
  margin-right: 8px;
  position: relative;
  transition: all 0.3s ease;
  flex-shrink: 0;
}

.ed-checkbox-label:hover input ~ .ed-checkmark {
  background-color: rgba(255, 255, 255, 0.1);
}

.ed-checkbox-label input:checked ~ .ed-checkmark {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.ed-checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 5px;
  top: 2px;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.ed-checkbox-label input:checked ~ .ed-checkmark:after {
  display: block;
}

.ed-checkbox-label span:last-child {
  margin-left: 4px;
}

@media (max-width: 768px) {
  .ed-data-selection {
    justify-content: center;
  }

  .ed-checkbox-label {
    padding: 6px 12px;
    font-size: 12px;
    min-height: 32px;
  }

  .ed-checkmark {
    height: 16px;
    width: 16px;
    margin-right: 6px;
  }

  .ed-checkmark:after {
    left: 4px;
    top: 2px;
    width: 3px;
    height: 7px;
  }
}

.ed-export-options {
  display: flex;
  gap: 16px;
}

.ed-format-select {
  flex: 1;
  padding: 10px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: rgba(255, 255, 255, 0.05);
  color: var(--text-color);
  border-radius: 6px;
  transition: border-color 0.3s ease;
}

.ed-format-select:focus {
  outline: none;
  border-color: var(--primary-color);
}

.ed-export-button {
  padding: 12px 24px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.1s ease;
  font-weight: bold;
}

.ed-export-button:hover {
  background-color: var(--primary-color-dark);
  transform: translateY(-2px);
}

.ed-export-button:active {
  transform: translateY(0);
}

.ed-error-message {
  margin-top: 16px;
  color: var(--danger-color);
  background-color: rgba(220, 53, 69, 0.1);
  padding: 12px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 10px;
  animation: ed-fadeIn 0.3s ease;
}

@keyframes ed-fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.ed-success-message {
  margin-top: 16px;
  color: var(--success-color);
  background-color: rgba(40, 167, 69, 0.1);
  padding: 12px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 10px;
  animation: ed-fadeIn 0.3s ease;
}
