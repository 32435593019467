.map-and-live-data-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 30px 0;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.map-container {
  position: relative;
  height: 250px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.map {
  height: 100%;
}

.update-location-btn {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 20px;
  font-size: 0.8rem;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 10px;
  flex-shrink: 0;
}

.update-location-btn:hover {
  background-color: var(--secondary-color);
}

.map-info {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px;
  font-size: 0.8rem;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.map-info p {
  margin: 0;
  flex-grow: 1;
  margin-right: 10px;
}

.data-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.data-section {
  background-color: var(--card-background);
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.section-title {
  color: var(--text-color);
  margin-bottom: 15px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 1.2rem;
}

.update-time {
  font-size: 0.7em;
  color: var(--secondary-color);
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 5px;
}

.measurement-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.measurement-item {
  flex: 1 1 calc(25% - 15px);
  min-width: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 10px;
  border-radius: 8px;
  background-color: rgba(var(--primary-color-rgb), 0.1);
}

.measurement-item:hover {
  background-color: rgba(128, 128, 128, 0.3);
  transition: background-color 0.3s ease;
}

.health-grid {
  display: flex;
  flex-wrap: nowrap;
  gap: 10px;
  overflow-x: auto;
  padding-bottom: 5px;
}

.health-item {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 5px 10px;
  border-radius: 8px;
  background-color: rgba(var(--secondary-color-rgb), 0.1);
  min-width: 100px;
}

.data-value {
  font-size: 1.4em;
  color: var(--primary-color);
  font-weight: 700;
  line-height: 1.2;
}

.health-item .data-value {
  font-size: 1em;
  color: var(--secondary-color);
}

.data-unit {
  font-size: 0.8em;
  color: var(--text-color);
  opacity: 0.7;
}

.data-label {
  font-size: 0.9em;
  color: var(--text-color);
  margin-top: 5px;
  font-weight: 500;
}

.health-item .data-label {
  order: -1;
  margin-bottom: 2px;
  margin-top: 0;
  font-size: 0.8em;
}

@media (max-width: 768px) {
  .map-and-live-data-container {
    margin: 20px 0;
    gap: 15px;
  }

  .map-container {
    height: 200px;
  }

  .data-section {
    padding: 15px;
  }

  .section-title {
    font-size: 1.1rem;
  }

  .measurement-item {
    flex: 1 1 calc(50% - 10px);
    min-width: 100px;
    padding: 8px;
  }

  .data-value {
    font-size: 1.2em;
  }

  .health-item .data-value {
    font-size: 0.9em;
  }

  .data-label, .data-unit {
    font-size: 0.8em;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.section-title svg {
  animation: pulse 2s infinite;
}

.edit-location-notes {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.edit-location-notes textarea {
  width: 100%;
  height: 60px;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  background-color: var(--card-background);
  color: var(--text-color);
  font-size: 0.8rem;
}

.button-group {
  display: flex;
  gap: 10px;
}

.submit-button,
.cancel-button {
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.submit-button {
  background-color: var(--primary-color);
  color: white;
}

.cancel-button {
  background-color: var(--secondary-background);
  color: var(--text-color);
}

.submit-button:hover,
.cancel-button:hover {
  opacity: 0.8;
}

.error-message {
  color: var(--danger-color);
  font-size: 0.9rem;
  margin-top: 5px;
}
