.gpr-generate-pdf-report {
  background-color: var(--card-background);
  border-radius: 12px;
  padding: 24px;
  margin-bottom: 24px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.gpr-generate-pdf-report:hover {
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.gpr-title {
  margin-bottom: 24px;
  color: var(--primary-color);
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  gap: 10px;
}

.gpr-report-form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.gpr-date-range {
  display: flex;
  gap: 16px;
}

.gpr-date-input {
  flex: 1;
  padding: 10px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: rgba(255, 255, 255, 0.05);
  color: var(--text-color);
  border-radius: 6px;
  transition: border-color 0.3s ease;
}

.gpr-date-input:focus {
  outline: none;
  border-color: var(--primary-color);
}

.gpr-generate-button {
  padding: 12px 24px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.gpr-generate-button:hover:not(:disabled) {
  background-color: var(--primary-color-dark);
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.gpr-generate-button:active:not(:disabled) {
  transform: translateY(0);
  box-shadow: none;
}

.gpr-generate-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.gpr-generate-button.gpr-loading {
  background-color: var(--secondary-color);
}

.gpr-error-message {
  margin-top: 16px;
  color: var(--danger-color);
  background-color: rgba(220, 53, 69, 0.1);
  padding: 12px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 10px;
  animation: gpr-fadeIn 0.3s ease;
}

.gpr-success-message {
  margin-top: 16px;
  color: var(--success-color);
  background-color: rgba(40, 167, 69, 0.1);
  padding: 12px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 10px;
  animation: gpr-fadeIn 0.3s ease;
}

@keyframes gpr-fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@media (max-width: 768px) {
  .gpr-date-range {
    flex-direction: column;
  }
}
