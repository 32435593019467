.gtp-panel {
  position: fixed;
  top: 20px;
  right: 0; /* Align to the right side of the screen */
  bottom: 20px;
  width: 400px; /* Fixed width for desktop */
  max-height: calc(100vh - 40px);
  overflow-y: auto;
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  z-index: 1000;
  font-family: 'Roboto', sans-serif;
  color: #333;
  transition: transform 0.3s ease-in-out;
  transform: translateX(0);
  border-radius: 10px 0 0 10px; /* Rounded edges on the left */
}

@media (max-width: 600px) {
  .gtp-panel {
    top: 10px;
    right: 10px;
    bottom: 10px;
    left: 10px;
    max-width: calc(100% - 20px);
    max-height: calc(100vh - 20px);
    transform: translateX(0);
    border-radius: 10px; /* Fully rounded edges for mobile */
  }
}

.gtp-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #666;
  transition: color 0.3s ease;
}

.gtp-close-button:hover {
  color: #333;
}

.gtp-panel h2 {
  margin-top: 0;
  margin-bottom: 5px;
  color: #1a1a1a;
  font-size: 1.5em;
}

.gtp-tracker-type {
  color: #333;
  margin-bottom: 15px;
  font-size: 0.9em;
}

.gtp-health-data {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 5px;
}

.gtp-health-item {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 0.9em;
  color: #1a1a1a;
}

.gtp-health-item svg {
  color: #2980b9;
}

.gtp-panel h3 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 1.2em;
  color: #1a1a1a;
  border-bottom: 1px solid #eee;
  padding-bottom: 5px;
}

.gtp-metadata p {
  margin: 5px 0;
  font-size: 0.9em;
  color: #333;
}

.gtp-location-history ul {
  list-style-type: none;
  padding: 0;
}

.gtp-location-history li {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 0.85em;
  background-color: #f9f9f9;
  padding: 8px;
  border-radius: 5px;
  color: #333;
}

.gtp-location-history li span {
  margin: 2px 0;
}

.gtp-location-timeline {
  position: relative;
  padding-left: 20px;
}

.gtp-location-item {
  position: relative;
  margin-bottom: 20px;
}

.gtp-location-dot {
  position: absolute;
  left: -25px;
  top: 5px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #3388ff;
}

.gtp-location-dot::before {
  content: '';
  position: absolute;
  left: 5px;
  top: 10px;
  height: calc(100% + 10px);
  width: 1px;
  background-color: #3388ff;
}

.gtp-location-item:last-child .gtp-location-dot::before {
  display: none;
}

.gtp-location-info {
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 5px;
}

.gtp-location-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.gtp-location-number {
  font-weight: bold;
  font-size: 1.2em;
  color: #3388ff;
}

.gtp-location-time {
  font-size: 0.9em;
  color: #666;
}

.gtp-location-time svg {
  margin-right: 5px;
}

.gtp-location-distance,
.gtp-location-elevation {
  display: flex;
  align-items: center;
  margin-top: 5px;
  font-size: 0.9em;
}

.gtp-location-distance svg,
.gtp-location-elevation svg {
  margin-right: 5px;
  color: #3388ff;
}

.gtp-location-elevation .fa-arrow-up {
  color: #4CAF50;
}

.gtp-location-elevation .fa-arrow-down {
  color: #F44336;
}

.gtp-custom-history-icon {
  background-color: #3388ff;
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
}

.gtp-history-marker {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gtp-latest-info {
  background-color: #f0f8ff;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;
}

.gtp-latest-info h3 {
  margin-top: 0;
  margin-bottom: 10px;
  color: #2c3e50;
}

.gtp-latest-info-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.gtp-info-item {
  display: flex;
  align-items: center;
  font-size: 0.9em;
}

.gtp-info-item svg {
  margin-right: 8px;
  color: #3498db;
}

.gtp-info-item span {
  display: flex;
  flex-direction: column;
}

.gtp-info-item strong {
  font-weight: bold;
  color: #2c3e50;
}

.gtp-public-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  padding: 15px;
  background-color: #f0f0f0;
  border-radius: 8px;
}

.gtp-public-toggle span {
  font-weight: bold;
  color: #333;
}

.gtp-public-toggle-heading {
  font-size: 1em;
  font-weight: bold;
  color: #2c3e50;
  margin-bottom: 10px;
  text-align: center;
}

.gtp-toggle-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.gtp-toggle-button:hover {
  background-color: #2980b9;
}

.gtp-toggle-button svg {
  font-size: 1.2em;
}

.gtp-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin: 0 10px;
}

.gtp-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.gtp-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.gtp-slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .gtp-slider {
  background-color: #2196F3;
}

input:checked + .gtp-slider:before {
  transform: translateX(26px);
}
