.sensor-header {
  background-color: var(--card-background);
  border-radius: 12px;
  padding: 16px 24px;
  margin-bottom: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon-button {
  background-color: transparent;
  color: var(--primary-color);
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
  transition: color 0.3s ease;
  padding: 8px;
}

.icon-button:hover {
  color: var(--secondary-color);
}

.sensor-info {
  flex-grow: 1;
  margin: 0 20px;
}

.sensor-name {
  font-size: 1.8rem;
  margin: 0 0 4px 0;
  color: var(--primary-color);
  font-weight: 600;
}

.sensor-details {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  color: var(--text-color-secondary);
}

.sensor-id, .last-update, .status {
  display: inline-flex;
  align-items: center;
}

.separator {
  margin: 0 8px;
  color: var(--text-color-secondary);
}

.status {
  font-weight: 500;
}

.status.active {
  color: var(--success-color);
}

.status.inactive {
  color: var(--danger-color);
}

.status svg {
  margin-right: 4px;
  font-size: 0.8em;
}

@media (max-width: 768px) {
  .sensor-header {
    padding: 12px 16px;
  }

  .sensor-name {
    font-size: 1.4rem;
  }

  .sensor-details {
    font-size: 0.8rem;
    flex-wrap: wrap;
  }

  .separator {
    display: none;
  }

  .sensor-id, .last-update, .status {
    margin-right: 8px;
  }
}


.edit-name-form {
  display: flex;
  align-items: center;
  gap: 10px;
}

.edit-name-input {
  font-size: 1.5rem;
  padding: 5px 10px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  background-color: var(--card-background);
  color: var(--text-color);
}

.submit-button,
.cancel-button {
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.submit-button {
  background-color: var(--primary-color);
  color: white;
}

.cancel-button {
  background-color: var(--secondary-background);
  color: var(--text-color);
}

.submit-button:hover,
.cancel-button:hover {
  opacity: 0.8;
}

.error-message {
  color: var(--danger-color);
  font-size: 0.9rem;
  margin-top: 5px;
}

@media (max-width: 768px) {
  .edit-name-form {
    flex-direction: column;
    align-items: flex-start;
  }

  .edit-name-input {
    width: 100%;
  }

  .submit-button,
  .cancel-button {
    width: 100%;
    margin-top: 5px;
  }
}
