.tabbed-container {
  background-color: var(--card-background);
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.tab-buttons {
  display: flex;
  background-color: var(--secondary-background);
  padding: 10px 10px 0;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.tab-buttons::-webkit-scrollbar {
  display: none;
}

.tab-button {
  padding: 12px 20px;
  border: none;
  background-color: transparent;
  color: var(--text-color);
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  white-space: nowrap;
  opacity: 0.7;
}

.tab-button.active {
  background-color: var(--card-background);
  color: var(--primary-color);
  opacity: 1;
  font-weight: bold;
}

.tab-content {
  padding: 20px;
}

.tab-content-inner {
  animation: slideIn 0.3s ease-out;
}

@keyframes slideIn {
  from { 
    opacity: 0;
    transform: translateY(10px);
  }
  to { 
    opacity: 1;
    transform: translateY(0);
  }
}

.notes-textarea {
  width: 100%;
  height: 150px;
  background-color: var(--secondary-background);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  color: var(--text-color);
  padding: 12px;
  font-size: 14px;
  resize: vertical;
}

.info-item {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid var(--border-color);
}

.info-label {
  font-weight: bold;
  margin-right: 10px;
  color: var(--primary-color);
}

.modern-button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s;
  margin-right: 10px;
  margin-bottom: 10px;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.5px;
}

.modern-button:hover {
  background-color: var(--primary-color-dark);
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.modern-button.danger {
  background-color: var(--danger-color);
}

.modern-button.danger:hover {
  background-color: var(--danger-color-dark);
}

.toggle-container {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
  margin-right: 10px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--secondary-background);
  transition: .4s;
  border-radius: 24px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: var(--primary-color);
}

input:checked + .slider:before {
  transform: translateX(26px);
}

@media (max-width: 768px) {
  .tab-buttons {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .tab-button {
    flex: 0 0 auto;
    font-size: 12px;
    padding: 10px 15px;
  }

  .modern-button {
    width: 100%;
    margin-right: 0;
  }
}

.notes-sensor-info {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  margin-right: 40px;
}

.notes-section,
.sensor-info-section {
  flex: 1;
  background-color: var(--card-background);
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.section-title {
  font-size: 1.2rem;
  color: var(--primary-color);
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.notes-content {
  flex-grow: 1;
  margin-bottom: 15px;
}

.notes-content textarea,
.notes-content p {
  width: 100%;
  height: 100%;
  min-height: 150px;
  background-color: var(--secondary-background);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  color: var(--text-color);
  padding: 12px;
  font-size: 14px;
  resize: none;
}

.notes-content p {
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-y: auto;
  margin: 0;
}

.notes-footer {
  margin-top: auto;
}

.button-group {
  display: flex;
  gap: 10px;
}

.save-button,
.cancel-button,
.edit-button {
  padding: 8px 16px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 5px;
}

.save-button {
  background-color: var(--primary-color);
  color: white;
}

.cancel-button {
  background-color: var(--secondary-background);
  color: var(--text-color);
}

.edit-button {
  background-color: var(--secondary-color);
  color: white;
}

.save-button:hover,
.cancel-button:hover,
.edit-button:hover {
  opacity: 0.8;
}

.error-message {
  color: var(--danger-color);
  margin-top: 10px;
}

.info-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 15px;
}

.info-item {
  background-color: var(--secondary-background);
  padding: 15px;
  border-radius: 10px;
  font-size: 0.9rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.info-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.info-label {
  font-weight: bold;
  color: var(--primary-color);
  display: block;
  margin-bottom: 5px;
  font-size: 0.8rem;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.info-value {
  color: var(--text-color);
  font-size: 1rem;
  word-break: break-word;
}

@media (max-width: 768px) {
  .notes-sensor-info {
    flex-direction: column;
  }

  .notes-section,
  .sensor-info-section {
    width: 100%;
  }

  .info-grid {
    grid-template-columns: 1fr 1fr;
  }
}
