.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-text {
  color: white;
  font-size: 18px;
  margin-top: 10px;
}

.dh-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  overflow: hidden;
  position: relative;
  padding: 20px;
  box-sizing: border-box;
}

.dh-background-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  z-index: 1;
  transition: opacity 1s ease;
}

.dh-background-default {
  background-color: #1a1a1a;
}

.dh-hub-selector {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  z-index: 10;
  flex-wrap: wrap;
}

.dh-hub-option {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: all 0.3s ease;
  width: 250px;
  max-width: 100%;
  margin: 10px;
  color: white;
}

.dh-hub-option:hover {
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
}

.dh-hub-option.active {
  background-color: var(--hub-color);
}

.dh-hub-icon {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--hub-color);
  border-radius: 50%;
  margin-bottom: 20px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.dh-hub-icon img {
  width: 60px;
  height: 60px;
  filter: brightness(0) invert(1);
}

.dh-hub-option h2 {
  font-size: 1.8rem;
  margin-bottom: 15px;
  text-align: center;
}

.dh-hub-description {
  text-align: center;
  font-size: 1rem;
  margin-top: 10px;
  opacity: 0.8;
}

.dh-back-button {
  margin-top: 40px;
  padding: 12px 24px;
  font-size: 1rem;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 10;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
}

.dh-back-button:hover {
  background-color: #555;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
}

@media (max-width: 768px) {
  .dh-hub-selector {
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;
  }

  .dh-hub-option {
    width: 80%;
    max-width: 300px;
  }
}

.dh-note {
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 15px 25px;
  border-radius: 12px;
  font-size: 0.9rem;
  text-align: center;
  width: fit-content;
  max-width: 80%;
  z-index: 10;
  backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  margin-top: 20px;
}

.dh-note a {
  color: #4CAF50;
  text-decoration: none;
}

.dh-note a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .dh-note {
    font-size: 0.8rem;
    padding: 12px 20px;
    max-width: 85%;
  }
}
