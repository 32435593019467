@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');

.sl-login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  background-image: url('../assets/login-page-background.jpg');
  background-size: cover;
  background-position: center;
  font-family: 'Poppins', sans-serif;
  padding: 20px;
  box-sizing: border-box;
}

.sl-login-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
}

.sl-login-half {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  color: white;
}

.sl-public {
  background: rgba(0, 0, 0, 0.5);
}

.sl-private {
  background: rgba(0, 0, 0, 0.6);
}

.sl-login-half h2 {
  margin-bottom: 1rem;
  font-size: 1.8rem;
  font-weight: 600;
  text-align: center;
}

.sl-login-half p {
  margin-bottom: 2rem;
  text-align: center;
  font-size: 1rem;
  opacity: 0.8;
}

.sl-public-button {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.sl-public-button:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

.sl-tab-container {
  display: flex;
  margin-bottom: 1rem;
  width: 100%;
  max-width: 300px;
}

.sl-tab {
  flex: 1;
  padding: 0.5rem;
  font-size: 0.9rem;
  background-color: transparent;
  color: white;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  opacity: 0.6;
}

.sl-tab.active {
  opacity: 1;
  border-bottom: 2px solid white;
}

.sl-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 300px;
}

.sl-input-group {
  position: relative;
  margin-bottom: 1.5rem;
}

.sl-input {
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  background-color: rgba(255, 255, 255, 0.1);
  border: none;
  border-radius: 4px;
  color: white;
  transition: all 0.3s ease;
}

.sl-input:focus {
  outline: none;
  background-color: rgba(255, 255, 255, 0.2);
}

.sl-label {
  position: absolute;
  left: 0.75rem;
  top: 0.75rem;
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.6);
  transition: all 0.3s ease;
  pointer-events: none;
}

.sl-input:focus + .sl-label,
.sl-input:not(:placeholder-shown) + .sl-label {
  top: -0.5rem;
  left: 0.5rem;
  font-size: 0.8rem;
  color: white;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 0 0.5rem;
  border-radius: 4px;
}

.sl-submit-button {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.sl-submit-button:hover {
  background-color: #45a049;
}

.sl-error-message {
  color: #ff6b6b;
  margin-bottom: 1rem;
  font-weight: bold;
  font-size: 0.9rem;
  text-align: center;
}

@media (min-width: 768px) {
  .sl-login-card {
    flex-direction: row;
    height: 500px;
  }
}

.sl-loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  backdrop-filter: blur(5px);
}

.sl-loader {
  width: 50px;
  height: 50px;
  border: 5px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: white;
  animation: spin 1s linear infinite;
  margin-bottom: 20px;
}

.sl-loading-overlay span {
  color: white;
  font-size: 1.2rem;
  font-weight: 500;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.sl-note {
  position: absolute;
  bottom: 20px;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 15px 25px;
  border-radius: 12px;
  font-size: 0.9rem;
  text-align: center;
  width: fit-content;
  max-width: 80%;
  backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  z-index: 10;
}

.sl-note a {
  color: #4CAF50;
  text-decoration: none;
}

.sl-note a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .sl-note {
    font-size: 0.8rem;
    padding: 12px 20px;
    max-width: 85%;
    margin-top: 20px;
  }
}
