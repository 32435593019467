html, body, #root, .App {
  height: 100%;
  margin: 0;
  padding: 0;
}

.map-component-container {
  position: relative;
  height: 100vh; 
  width: 100%;
  overflow: hidden;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.map {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.map-button {
  position: absolute;
  z-index: 1000;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  background-color: rgba(255, 255, 255, 0.9);
  color: #333;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: bold;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.map-button:hover {
  background-color: #fff;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.map-button svg {
  margin-right: 5px;
}

.back-button {
  top: 20px;
  left: 20px;
}

.add-sensor-button {
  top: 20px;
  left: 200px;
}

.filter-button {
  top: 20px;
  right: 20px;
}

.loading-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loader {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-bottom: 10px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  animation: fadeIn 0.3s ease;
}

.popup {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  width: 350px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  position: relative;
  animation: slideIn 0.3s ease;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  color: #999;
  transition: color 0.3s ease;
}

.close-button:hover {
  color: #333;
}

.popup h2 {
  margin-top: 0;
  margin-bottom: 20px;
  color: #333;
}

.popup input {
  width: 100%;
  padding: 12px;
  margin-bottom: 15px;
  border-radius: 5px;
  border: 1px solid #ddd;
  font-size: 14px;
  transition: border-color 0.3s ease;
}

.popup input:focus {
  outline: none;
  border-color: #3498db;
}

.button-container {
  display: flex;
  justify-content: space-between;
}

.submit-button, .cancel-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.3s ease;
}

.submit-button {
  background-color: #3498db;
  color: #fff;
}

.submit-button:hover {
  background-color: #2980b9;
}

.cancel-button {
  background-color: #e74c3c;
  color: #fff;
}

.cancel-button:hover {
  background-color: #c0392b;
}

.error-message, .success-message {
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
}

.error-message {
  background-color: #ffebee;
  color: #c62828;
}

.success-message {
  background-color: #e8f5e9;
  color: #2e7d32;
}

.filter-popup {
  position: absolute;
  top: 70px;
  right: 20px;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.95);
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  max-width: 350px;
  width: calc(100% - 40px);
  max-height: calc(100vh - 100px);
  overflow-y: auto;
  animation: slideIn 0.3s ease;
}

.filter-popup h3 {
  font-size: 18px;
  margin-bottom: 15px;
  color: #333;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.filter-section {
  margin-bottom: 20px;
}

.filter-section h4 {
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 10px;
  color: #666;
}

.select-buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.select-buttons button {
  padding: 8px 12px;
  font-size: 14px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.select-buttons button:hover {
  background-color: #e0e0e0;
}

.filter-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 10px;
}

.filter-chip {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  background-color: #f0f0f0;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 14px;
}

.filter-chip:hover {
  background-color: #e0e0e0;
}

.filter-chip.selected {
  background-color: #3498db;
  color: white;
}

.filter-chip input {
  display: none;
}

.filter-chip span {
  margin-left: 5px;
}

/* Mobile-friendly styles */
@media (max-width: 768px) {
  .map-button {
    left: 10px;
    right: auto;
  }

  .back-button {
    top: 10px;
  }

  .add-sensor-button {
    top: 60px;
  }

  .filter-button {
    top: 110px;
  }

  .filter-popup {
    top: auto;
    bottom: 20px;
    left: 20px;
    right: 20px;
    max-width: none;
  }

  .filter-grid {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  }

  .filter-chip {
    font-size: 13px;
    padding: 8px 10px;
  }
}

.user-info-box {
  position: absolute;
  bottom: 10px;
  left: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  z-index: 1000;
  display: flex;
  align-items: center;
}

.map-mode-toggle {
  margin-left: 10px;
  background-color: #4a4a4a;
  border: none;
  color: white;
  padding: 5px 10px;
  border-radius: 3px;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.map-mode-toggle:hover {
  background-color: #5a5a5a;
}

/* Mobile-friendly adjustment */
@media (max-width: 768px) {
  .user-info-box {
    flex-direction: column;
    align-items: flex-start;
  }

  .map-mode-toggle {
    margin-left: 0;
    margin-top: 5px;
  }
}

.filter-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  color: #666;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.3s ease;
}

.filter-close-button:hover {
  color: #333;
}

@media (max-width: 768px) {
  .filter-popup {
    position: fixed;
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    max-width: 100%;
    width: 100vw;
    height: 50vh;
    margin: 0;
    padding: 20px;
    border-radius: 20px 20px 0 0;
    box-shadow: 0 -4px 20px rgba(0, 0, 0, 0.15);
    overflow-y: auto;
    box-sizing: border-box;
    transform: translateY(100%);
    opacity: 0;
    transition: transform 0.3s ease-out, opacity 0.2s ease-out;
  }

  .filter-popup.active {
    transform: translateY(0);
    opacity: 1;
  }

  /* Remove the map resizing styles */
  .map-component-container.filter-open .map {
    height: 100% !important;
  }

  .map-component-container.filter-open {
    height: 100vh;
    overflow: hidden;
  }

  /* Remove the old animation */
  @keyframes slideUp {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }
}
