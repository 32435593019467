.gps-tracker-marker {
  width: 30px;
  height: 30px;
}

.marker-icon {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #3388ff;
  border: 2px solid #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  position: relative;
  overflow: hidden;
}

.marker-icon::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  height: 60%;
  background-color: #ffffff;
  border-radius: 50%;
  opacity: 0.5;
}

.marker-icon.unknown {
  background-color: #9c27b0;
}

.gps-tracker-popup {
  min-width: 200px;
}

.gps-tracker-popup .leaflet-popup-content-wrapper {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 8px;
  box-shadow: 0 3px 14px rgba(0,0,0,0.4);
}

.gps-tracker-popup .leaflet-popup-tip-container {
  display: none;
}

.gps-tracker-popup h3 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 1.2em;
  color: #333;
}

.gps-tracker-popup p {
  margin: 5px 0;
  font-size: 0.9em;
  color: #666;
}

.gps-tracker-popup strong {
  color: #333;
}
